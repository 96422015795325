import { useContext, useEffect } from 'react';
import Bell from '../components/Bell/Bell';
import DrawerContainer from '../components/DrawerContainer';
import AppContext from '../context/Provider';
import { notificationsReadFailed, notificationsReceivedFailed } from '../utils/localStorageManager';
import { INotificationsRead, INotificationsReceived } from '../../interfaces/INotificationsPayloads';

const BellContainer = () => {
  const { markNotificationRead, markNotificationReceived } = useContext(AppContext);

  useEffect(() => {
    const aMinute = 60000;
    const fetchDataAsync = () => {
      const notificationAsReceivedSavedInLocalStorage: INotificationsReceived[] = JSON.parse(
        localStorage.getItem(notificationsReceivedFailed),
      );
      const notificationAsReadSavedInLocalStorage: INotificationsRead[] = JSON.parse(localStorage.getItem(notificationsReadFailed));

      if (notificationAsReceivedSavedInLocalStorage?.length) {
        markNotificationReceived(notificationAsReceivedSavedInLocalStorage);
      }

      if (notificationAsReadSavedInLocalStorage?.length) {
        markNotificationRead(notificationAsReadSavedInLocalStorage);
      }
    };

    fetchDataAsync();

    const interval = setInterval(fetchDataAsync, aMinute); // 1'

    return () => clearInterval(interval);
  }, [markNotificationRead, markNotificationReceived]);

  return (
    <>
      <Bell />
      <DrawerContainer />
    </>
  );
};

export default BellContainer;
