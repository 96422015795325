import { notificationsReadFailed, notificationsReceivedFailed } from "./localStorageManager";

export const handleMarkNotificationReceivedError = notificationsFailed => {
  const notificationSavedInLocalStorage = JSON.parse(localStorage.getItem(notificationsReceivedFailed)) || [];

  const setNotificationsFailed = new Set(notificationSavedInLocalStorage.map(item => item.receivedAt));
  const notificationSavedFiltered = notificationsFailed.filter(item => !setNotificationsFailed.has(item.receivedAt));

  const newNotificationsToSave = [...notificationSavedInLocalStorage, ...notificationSavedFiltered];

  localStorage.setItem(notificationsReceivedFailed, JSON.stringify(newNotificationsToSave));
};

export const handleMarkNotificationReadError = notificationsFailed => {
  const notificationSavedInLocalStorage = JSON.parse(localStorage.getItem(notificationsReadFailed)) || [];

  const setNotificationsIdsFailed = new Set(notificationSavedInLocalStorage.map(item => item.notificationId));
  const notificationSavedFiltered = notificationsFailed.filter(item => !setNotificationsIdsFailed.has(item.notificationId));

  const newNotificationsToSave = [...notificationSavedInLocalStorage, ...notificationSavedFiltered];

  localStorage.setItem(notificationsReadFailed, JSON.stringify(newNotificationsToSave));
};