import { Stack, Skeleton } from '@chakra-ui/react';

interface Props {
  repeatNumSkeleton?: number;
  id: string;
  sx?: object;
  testId?: string;
}

function SkeletonOnLoading({ repeatNumSkeleton = 1, id, sx, testId = 'skeleton' }: Props) {
  const skeletonList = Array.from({ length: repeatNumSkeleton }, () => 1);
  return <Stack data-testid={testId}>{skeletonList?.map((_, index) => <Skeleton key={`${id}-${index}`} {...(sx && { ...sx })} />)}</Stack>;
}

export default SkeletonOnLoading;
