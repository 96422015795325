import React from 'react';

const AdministracionIcon = () => {
  return (
    <svg
    viewBox="11 17 22 15"
    width="44"
    height="44"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      fill="#2172DC"
      d="M24 24.852c-1.087 0-2.002-.362-2.742-1.086-.74-.724-1.111-1.618-1.111-2.681s.37-1.957 1.11-2.681c.741-.724 1.655-1.087 2.743-1.087 1.087 0 2.002.363 2.742 1.087.74.724 1.111 1.618 1.111 2.681s-.37 1.957-1.11 2.681c-.741.724-1.655 1.087-2.743 1.087Zm0-1.65c.637 0 1.158-.197 1.56-.59.404-.395.606-.904.606-1.527 0-.623-.202-1.132-.605-1.526-.403-.394-.924-.591-1.561-.591-.637 0-1.158.197-1.56.59-.404.395-.606.904-.606 1.527 0 .623.202 1.132.605 1.526.403.394.924.591 1.561.591ZM24 35c-2.625-.642-4.781-2.131-6.469-4.469-1.687-2.337-2.531-4.9-2.531-7.686V16.3l9-3.3 9 3.3v6.545c0 2.787-.844 5.349-2.531 7.686C28.78 32.87 26.625 34.358 24 35Zm0-20.24-7.313 2.695v5.39c0 1.155.165 2.26.493 3.314.328 1.054.792 2.03 1.392 2.928a11.935 11.935 0 0 1 2.63-1.03A11.19 11.19 0 0 1 24 27.711c.975 0 1.908.115 2.798.344.891.23 1.768.573 2.63 1.031.6-.898 1.064-1.874 1.392-2.928.328-1.054.492-2.159.492-3.314v-5.39L24 14.76Zm0 14.603c-.731 0-1.462.091-2.194.274-.731.184-1.453.459-2.165.825.6.642 1.265 1.206 1.997 1.692A9.65 9.65 0 0 0 24 33.295a9.652 9.652 0 0 0 2.363-1.141 11.237 11.237 0 0 0 1.996-1.692 10.084 10.084 0 0 0-2.165-.825A8.992 8.992 0 0 0 24 29.363Z"
    />
  </svg>
  );
};

export default AdministracionIcon;
