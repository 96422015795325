/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import getTokenForSessionStorage from '../src/utils/sesionStorage';
import { INotificationsReadPayload, INotificationsReceivedPayload } from '../interfaces/INotificationsPayloads';

const newAxios = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    source: 'WEB',
    requestId: crypto.getRandomValues(new Uint8Array(8)).join('')
  }
});

newAxios.interceptors.request.use(
  (config) => {
    const token = getTokenForSessionStorage()
    config.headers.Authorization = `Bearer ${token}`
    return config
  }
);

export const getNotifications = async () => {
  const response = await newAxios.get('notifications');
  return response.data.data;
};
export const getOperatorNotificationsConfig = () => newAxios.get('notifications-settings-mock');
export const updateOperatorNotificationsConfig = payload => newAxios.post('notifications-settings-mock', payload);

export const markNotificationAsReadRequest = async (body: INotificationsReadPayload) => await newAxios.post('notifications/read', body);
export const markNotificationAsReceivedRequest = async (body: INotificationsReceivedPayload) => await newAxios.post('notifications/received', body);

