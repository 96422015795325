import AdministracionIcon from './AdministracionIcon';
import PagosIcon from './PagosIcon';
import TransferenciaIcon from './TransferenciaIcon';
import { useIntl } from 'react-intl';

const ConfigTexts = () => {
  const intl = useIntl();
  return {
    PAYMENTS: {
      //category
      title: 'Pagos',
      icon: <PagosIcon />,
      'PAYMENTS.PAYMENT_EXPIRES_TODAY': {
        subTitle: intl.formatMessage({
          id: 'components.ConfigNotification.PAYMENTS.PAYMENTS.PAYMENT.EXPIRES_TODAY.subTitle',
          defaultMessage: 'Facturas por vencer',
        }),
        description: intl.formatMessage({
          id: 'components.ConfigNotification.PAYMENTS.PAYMENTS.PAYMENT.EXPIRES_TODAY.description',
          defaultMessage: 'Recibirás una notificación cada vez que tengas facturas de un servicio próximas a vencer.',
        }),
      },
    },
    TRANSFERS: {
      //category
      title: 'Transferencias',
      icon: <TransferenciaIcon />,
      subTitle: 'Transferencias en proceso',
      description: 'Recibirás una notificación cada vez que tengas facturas de un servicio próximas a vencer.',
    },
    ADMINISTRATION: {
      //category
      title: 'Administración',
      icon: <AdministracionIcon />,
      ACCOUNTS: 'Cuentas', //subcategory
      'ADMINISTRATION.ACCOUNTS.BANK_ENROLLMENT_APPROVED': {
        //domainEvent
        subTitle: intl.formatMessage({
          id: 'components.ConfigNotification.ADMINISTRATION.ACCOUNTS.ACCOUNT_ENROLLMENT_CONFIRM.subTitle',
          defaultMessage: 'Autorización o rechazo del segundo administrador',
        }),
        description: intl.formatMessage({
          id: 'components.ConfigNotification.ADMINISTRATION.ACCOUNTS.ACCOUNT_ENROLLMENT_CONFIRM.description',
          defaultMessage:
            'Recibirás una notificación con la respuesta del segundo administrador cuando agregues, modifiques o elimines una cuenta.',
        }),
      },
      'ADMINISTRATION.ACCOUNTS.ACCOUNT_ENROLLMENT_CONFIRM': {
        subTitle: intl.formatMessage({
          id: 'components.ConfigNotification.ADMINISTRATION.ACCOUNTS.BANK_ENROLLMENT_APPROVED.subTitle',
          defaultMessage: 'Aprobación o rechazo del banco',
        }),
        description: intl.formatMessage({
          id: 'components.ConfigNotification.ADMINISTRATION.ACCOUNTS.BANK_ENROLLMENT_APPROVED.description',
          defaultMessage:
            'Recibirás una notificación con la respuesta del banco cuando se agregue, modifique o elimine una cuenta con la que tengas permisos.',
        }),
      },
      COLLABORATOR: 'Colaboradores',
      'ADMINISTRATION.COLLABORATOR.ENROLLMENT_CONFIRM': {
        subTitle: intl.formatMessage({
          id: 'components.ConfigNotification.ADMINISTRATION.COLABORADOR.ENROLLMENT_CONFIRM.subTitle',
          defaultMessage: 'Autorización o rechazo del segundo administrador',
        }),
        description: intl.formatMessage({
          id: 'components.ConfigNotification.ADMINISTRATION.COLABORADOR.ENROLLMENT_CONFIRM.description',
          defaultMessage:
            'Recibirás una notificación con la respuesta del segundo administrador cuando agregues, modifiques o elimines un operador.',
        }),
      },
    },
  };
};
export default ConfigTexts;
