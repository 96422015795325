import React from 'react';
import { useIntl } from 'react-intl';
import { ToastComponent } from '@wow/toast';
import { Accordion, AccordionItem, AccordionPanel, Text, Switch, Flex } from '@chakra-ui/react';
import BodyAccordion from './components/BodyAccordion';
import AccordionButton from './components/AccordionButton';
import ConfigTexts from './components/ConfigTexts';
import { PropsNotificationsConfig } from './INotificationsConfig';
import useChakraToast from '../../hooks/useChakraToast';

const ConfigNotification = (props: PropsNotificationsConfig) => {
  const intl = useIntl();
  const { showToast, setIsActiveToast } = useChakraToast();

  const {
    operatorNotificationsConfig,
    updateOperatorNotificationsConfigMutation,
    isLoadingUpdateOperatorNotificationsConfig,
  }: PropsNotificationsConfig = props;

  const handleSubCategorySwitchChange = (notifications, newIsEnabled) => {
    notifications.forEach(notification => {
      const { domainEvent, channels } = notification;
      channels.forEach(channel => {
        if (channel.isEnabled !== newIsEnabled) {
          handleNotificationSwitchChange({
            domainEvent,
            channelType: channel.channelType,
            isEnabled: newIsEnabled,
          });
        }
      });
    });
  };

  const handleNotificationSwitchChange = async payload => {
    setIsActiveToast(true);

    try {
      await updateOperatorNotificationsConfigMutation(payload);
    } catch (error) {
      showToast({
        status: 'error',
        description: intl.formatMessage({
          id: 'components.ConfigNotification.component.toast.defaultError.message',
          defaultMessage: 'No podemos cambiar tu configuración. Intentá de nuevo.',
        }),
        render: props => <ToastComponent {...props} />,
      });
    }

    setIsActiveToast(false);
  };
  return (
    <Accordion allowMultiple w='935px'>
      {operatorNotificationsConfig.map(({ category, subCategories }, index) => {
        const resultConfig = ConfigTexts()[category];

        return (
          <React.Fragment key={`NotificationsConfig_${index}-${category}`}>
            <AccordionItem id={`NotificationsConfig_${index}`} border='1px solid #B2B7C4' borderRadius='8px' mb='8px'>
              {({ isExpanded }) => (
                <>
                  <AccordionButton key={`AccordionButton_${index}-${category}`} {...{ isExpanded, resultConfig }} />
                  <AccordionPanel p={0} minH='64px' borderTop='1px solid #C7C6CA'>
                    {subCategories?.map(({ subCategory, displaySubCategory, notifications }, index) => {
                      const isSubCategoryEnabled = notifications.every(notification =>
                        notification.channels.every(channel => channel.isEnabled),
                      );

                      return (
                        <React.Fragment key={`subcategories-${category}-${subCategory}-${index}`}>
                          {displaySubCategory && (
                            <Flex bg='#0000000A' justifyContent='space-between' p='12px 14px' borderBottom='1px solid #C7C6CA'>
                              <Text fontSize='14px' fontWeight='700' lineHeight='20px' data-testid='accordion-subcategory-title'>
                                {resultConfig?.[subCategory] || null}
                              </Text>
                              <Switch
                                name={subCategory}
                                isChecked={isSubCategoryEnabled}
                                onChange={() => handleSubCategorySwitchChange(notifications, !isSubCategoryEnabled)}
                                isDisabled={isLoadingUpdateOperatorNotificationsConfig}
                                data-testid='accordion-subcategory-switch'
                                marginRight='8px'
                              />
                            </Flex>
                          )}
                          <BodyAccordion
                            resultConfig={resultConfig}
                            notifications={notifications}
                            handleNotificationSwitchChange={handleNotificationSwitchChange}
                            isLoadingUpdateOperatorNotificationsConfig={isLoadingUpdateOperatorNotificationsConfig}
                          />
                        </React.Fragment>
                      );
                    })}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </React.Fragment>
        );
      })}
    </Accordion>
  );
};

export default ConfigNotification;
