import { useIntl } from 'react-intl';
import { Card } from '@wow/card';
import { Button, Text } from '@chakra-ui/react';
import { ChevronRight } from 'react-feather';
import { CardSectionHorizontal, TextContainer, styleCardContainer } from './styled';
import { INotificationList } from '../../../../context/IAppContextProvider';
import dayjs from 'dayjs';
import { lowerCaseSnakeFormat } from '../../../../utils/converters';
interface Props extends Partial<INotificationList> {
  handleClickNotification: (props: Partial<INotificationList>) => void;
}

const CardNotification = (props: Props) => {
  const intl = useIntl();
  const { id, isNotificationRead, title, body, sentAt, handleClickNotification, domainEvent, callToAction } = props;
  const date = dayjs(new Date(sentAt)).format('DD/MM/YYYY - HH:mm');

  return (
    <Card style={styleCardContainer} data-testid='card__notification'>
      <CardSectionHorizontal>
        <div className='metaContainer'>
          {!isNotificationRead && (
            <svg data-testid='meta' xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'>
              <circle cx='6' cy='6.5' r='6' fill='#F03C3C' />
            </svg>
          )}
        </div>
        <TextContainer>
          <Text textStyle='overlineLg'>{date}H</Text>
          <Text textStyle='titleMdBold'>{title}</Text>
          <Text textStyle='bodyMdSemi'>{body}</Text>
        </TextContainer>
        <div>
          <Button
            variant='primary-text'
            rightIcon={<ChevronRight />}
            onClick={() => handleClickNotification({ callToAction, id, isNotificationRead, domainEvent })}
            size='md'
            padding={0}
          >
            {intl.formatMessage({
              id: `components.ConfigNotification.${lowerCaseSnakeFormat(domainEvent)}.cta.redirect.label`,
              defaultMessage: 'Revisar solicitud',
            })}
          </Button>
        </div>
      </CardSectionHorizontal>
    </Card>
  );
};

export default CardNotification;
