import { ToastProps, useToast } from '@chakra-ui/react';
import { useState } from 'react';

const useChakraToast = () => {
  const toast = useToast();
  const [activeToast, setIsActiveToast] = useState<boolean>(false);
  const showToast = (toastProps: ToastProps) => {
    toast.closeAll();
    const id = `modal-toast-${Date.now()}`;
    if (activeToast && toast.isActive(id)) {
      return;
    }
    toast({
      id,
      duration: 5000,
      position: 'bottom-left',
      containerStyle: { marginLeft: '65px', color: '#fff' },
      isClosable: true,
      ...toastProps,
    });
  };

  return { showToast, activeToast, setIsActiveToast };
};

export default useChakraToast;
