import React from 'react';

const PagosIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='11 17 25 14' width='44' height='44' fill='none'>
      <path
        fill='#2172DC'
        fillRule='evenodd'
        d='M12 18a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H15a3 3 0 0 1-3-3V18Zm2 0a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v3H14v-3Zm20 5v7a1 1 0 0 1-1 1H15a1 1 0 0 1-1-1v-7h20Z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default PagosIcon;
