import { BreadcrumbComponent } from '@wow/breadcrumb';
import { Outlet } from 'react-router-dom';
import useBreadcrumb from '../hooks/useBreadcrumb';
import Style from './styled';

function NotificationLayout() {
  const [breadcrumbItems] = useBreadcrumb();

  return (
    <Style>
      <BreadcrumbComponent items={breadcrumbItems} margin='14px 0' marginBottom={0} />
      <Outlet />
    </Style>
  );
}

export default NotificationLayout;
