import { Button, CloseButton, Text } from '@chakra-ui/react';
import { Modal as ModalDS, ModalOverlay, ModalContent, ModalBody, ModalFooter, ModalHeader, ModalChakra } from '@wow/modal';
import { useContext } from 'react';
import AppContext from '../../context/Provider';
import configModal from './config/configModal';

const Modal = () => {
  const { modalConfig, setModalConfig } = useContext(AppContext);
  const onClose = () => setModalConfig(null);
  const { iconType, title, description, successButton } = configModal()[modalConfig?.type] || {};

  return (
    <ModalDS isOpen={modalConfig?.isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW='576px' style={{ paddingLeft: '43px', paddingRight: '43px'}}>
        <CloseButton onClick={onClose} sx={ModalChakra.close} />
        <ModalHeader state={iconType} alignItems='center' textAlign='center' sx={{ textWrap: 'pretty' }}>
          {title}
        </ModalHeader>
        <ModalBody>
          <Text dangerouslySetInnerHTML={{ __html: description }} lineHeight='22px'></Text>
        </ModalBody>
        <ModalFooter justifyContent='center'>
          {successButton && (
            <Button variant='primary' onClick={onClose}>
              {successButton}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </ModalDS>
  );
};
export default Modal;
