import { Route, Routes as RoutesDom } from 'react-router-dom';

import Settings from '../layouts/Settings';
import { RoutesName } from './routesNames';
import NotificationLayout from '../layouts/NotificationLayout';
import ErrorPage from '../components/ErrorPage';

function Routes() {
  return (
    <RoutesDom>
      <Route path='/' element={<NotificationLayout />}>
        <Route index path={RoutesName.CONFIGURACIONES} element={<Settings />} />
      </Route>
      <Route path='*' element={<ErrorPage />} />
    </RoutesDom>
  );
}

export default Routes;
