import { Box, Grid, GridItem, Text } from '@chakra-ui/react';

import Style from './styled';

interface ICommonPage {
  headline: string;
  subheader: string | React.ReactNode;
  text: string | React.ReactNode;
  children: React.ReactNode;
  buttons: React.ReactNode;
  buttonsSecond: React.ReactNode;
  showBreadcrumb: boolean;
}

function CommonPage({ headline, subheader, text, children, showBreadcrumb, buttons, buttonsSecond }: Partial<ICommonPage>) {
  return (
    <Style>
      {showBreadcrumb}
      <Grid templateColumns='repeat(12, 1fr)' gap={1}>
        {headline && (
          <GridItem className='headline' colSpan={10}>
            <Text textStyle='h2Bold'>{headline}</Text>
          </GridItem>
        )}
        {buttons && (
          <GridItem colSpan={2}>
            <Box className='buttons'>{buttons}</Box>
          </GridItem>
        )}
        {subheader && (
          <GridItem colSpan={10}>
            <Text style={{ marginTop: '12px', marginBottom: `${text ? '0' : '12px'}` }} className='subheader' textStyle='h3'>
              {subheader}
            </Text>
          </GridItem>
        )}
        {buttonsSecond && (
          <GridItem colSpan={2}>
            <Box className='buttons'>{buttonsSecond}</Box>
          </GridItem>
        )}
        {text && (
          <GridItem className='container-text' colSpan={12}>
            <Text textStyle='bodyMd'>{text}</Text>
          </GridItem>
        )}
        <GridItem colSpan={12}>{children}</GridItem>
      </Grid>
    </Style>
  );
}

export default CommonPage;
