import styled from 'styled-components';
import { themeChakra } from '@wow/themes';

const {
  colors: { error },
} = themeChakra;
// eslint-disable-next-line import/prefer-default-export
export const StyledNotificationsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: 0;
  position: relative;
  padding: 0;
  cursor: pointer;
  button > svg {
    color: #2172dc;
    width: 24px;
    height: 24px;
  }
  > span {
    position: absolute;
    right: 1px;
    top: -4px;
    border-color: #ba1b1b;
    background: ${error[100]};
    width: 20px;
    height: 20px;
  }
`;
