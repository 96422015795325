import { Box, Button, Text } from '@chakra-ui/react'
import { useIntl } from 'react-intl'

import { CONFIG } from './components/config'
import { IEmptyStateScreen } from './IEmptyStateScreen'
import { Container } from './styled'

function EmptyStateScreen({ onRetry, variantButton = 'primary-text', errorVariant, ...rest }: Partial<IEmptyStateScreen>) {
  const intl = useIntl()
  const resultConfig = CONFIG[errorVariant]
  return (
    <Container data-testid={`empty-${rest?.title || ''}`}>
      {resultConfig?.image || rest?.picture}
      <Text textStyle='h4Bold' className='empty-state-screen-title'>
        {intl.formatMessage({
          id: `components.EmptyStateScreen.component.${errorVariant}.title`,
          defaultMessage: resultConfig.title
        }) || rest?.title}
      </Text>
      <Text
        textStyle='bodyLg'
        className='empty-state-screen-subtitle'
        dangerouslySetInnerHTML={{
          __html:
            intl.formatMessage({
              id: `components.EmptyStateScreen.component.${errorVariant}.subtitle`,
              defaultMessage: resultConfig.subtitle
            }) || rest?.subtitle
        }}
      />
      {onRetry && (
        <Box className='empty-state-screen-button'>
          <Button variant={variantButton} onClick={onRetry} size='md'>
            {rest?.buttonLabel}
          </Button>
        </Box>
      )}
    </Container>
  )
}
export default EmptyStateScreen
