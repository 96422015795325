import React from 'react';
import { AccordionButton as AccordionButtonChrakra, Text, Flex } from '@chakra-ui/react';
import { IconButtonComponent } from '@wow/button';
import { ChevronUp, ChevronDown } from 'react-feather';

const AccordionButton = ({ isExpanded, resultConfig }) => {
  return (
    <AccordionButtonChrakra
      height='72px'
      p='12px'
      _expanded={{ bg: 'transparent' }}
  
    >
      <Flex w='200px' alignItems='center'>
        <IconButtonComponent icon={resultConfig?.icon} aria-label='icon-2' variant='icon-text' size='i-lg' fontSize='24px' />
        <Text ml='8px' fontSize='16px' fontWeight='700' data-testid='accordion-category-title'>
          {resultConfig?.title}
        </Text>
      </Flex>

      <IconButtonComponent
        aria-label='icon-2'
        icon={isExpanded ? <ChevronUp /> : <ChevronDown />}
        size='i-lg'
        variant='icon-outline'
        data-testid='accordion-button'
      />
    </AccordionButtonChrakra>
  );
};

export default AccordionButton;
