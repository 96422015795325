import React from 'react';

const TransferenciaIcon = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="11 17 22 15"
    width="44"
    height="44"
    fill="none"
  >
    <path
      fill="#2172DC"
      fillRule="evenodd"
      d="M18.707 15.293a1 1 0 0 1 0 1.414L17.414 18H33a1 1 0 1 1 0 2H17.414l1.293 1.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0Zm10.586 9.5a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414-1.414l1.293-1.293H15a1 1 0 1 1 0-2h15.586l-1.293-1.293a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  </svg>
  );
};

export default TransferenciaIcon;
