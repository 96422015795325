import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { RoutesName } from '../routes/routesNames';
import { capitalizeFirst } from '../utils/converters';

const breadCrumbNames = {
  // 
};

const customRoutes = {
  [`/configuracion`]: 'Configurar notificaciones',
};

export interface Items {
  key: string;
  id: string;
  link: string;
  label: string;
  isCurrentPage?: boolean;
}

function nomalizeUrl(str: string) {
  const words = str.split(/(?=[A-Z])/);
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
const ROOT = 'inicio';
const ROUTE_NOTIFICATION = 'notificaciones';
const useBreadcrumb = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<Items[]>([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    let sections = pathname.split('/').filter(item => item !== '');
    sections = sections.filter(itemPath => itemPath !== ROUTE_NOTIFICATION);

    const path = '';
    const breadcrumbSections = [];
    // add the "Inicio" section directly to the beginning and redirect to root
    breadcrumbSections.push({
      key: ROOT,
      id: ROOT,
      label: capitalizeFirst(ROOT),
      link: `${RoutesName.ROOT}`,
      onClick: e => {
        e.preventDefault();
        navigate(`${RoutesName.ROOT}`);
      },
    });
    for (let i = 0; i < sections.length; i += 1) {
      const temPath = `${path}/${sections.slice(0, i + 1).join('/')}`;
      const label = sections[i]
        .split(' ')
        .map(word => nomalizeUrl(word))
        .join(' ');
        const customLabel = customRoutes[temPath];
      const finalLabel = customLabel ?? breadCrumbNames[label] ?? label;

     
      breadcrumbSections.push({
        key: i,
        id: i,
        label: finalLabel,
        link: '',
        isCurrentPage: i === sections.length - 1,
        onClick: e => {
          e.preventDefault();
          navigate(temPath);
        },
      });
    }
    setBreadcrumbItems(breadcrumbSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return [breadcrumbItems];
};

export default useBreadcrumb;
