import Modal from './components/Modal/Modal';
import AppContextProvider from './context/AppContextProvider';
import BellContainer from './layouts/BellContainer';
import { RootContainer } from './root.component';

function App() {
  return (
    <RootContainer>
      <AppContextProvider>
        <BellContainer />
        <Modal/>
      </AppContextProvider>
    </RootContainer>
  );
}

export default App;
