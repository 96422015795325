const SCREEN_1280PX = 1280;
const SCREEN_1440PX = 1440;
const SCREEN_1920PX = 1920;

export const getNumRepeatSekeleton = (width: number | undefined) => {
  if (!width || width <= SCREEN_1280PX) {
    return 5;
  }
  if (width <= SCREEN_1440PX) {
    return 7;
  }
  if (width <= SCREEN_1920PX) {
    return 8;
  }
  return 4;
};
