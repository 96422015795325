import { Box, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';

import { textLoader } from '../../constants/generalText';
import { Content, TextTitle } from './styled';

interface Props {
  title: string;
  description: string;
  testId: string;
  variant: string;
}

function Loader({
  title = textLoader.title,
  description = textLoader.description,
  variant = 'default',
  testId = 'loader',
}: Partial<Props>) {
  const intl = useIntl();
  return (
    <Box sx={Content}>
      <Spinner variant={variant} size='xl' data-testid={testId} />
      <Text sx={TextTitle} textStyle='h4Bold'>
        {intl.formatMessage({ id: 'components.loader.title', defaultMessage: title })}
      </Text>
      <Text m={0} textStyle='bodyLg'>
        {intl.formatMessage({ id: 'components.loader.description', defaultMessage: description })}
      </Text>
    </Box>
  );
}

export default Loader;
