const getTokenForSessionStorage = (): string => {
  const sessionDataString = `oidc.user:${process.env.SESSION_DATA_STRING}`;
  let idToken = '';

  if (sessionDataString) {
    const dataString = sessionStorage.getItem(sessionDataString);

    if (dataString) {
      const data = JSON.parse(dataString);

      idToken = data?.id_token;
    }
  }
  return idToken;
};

export default getTokenForSessionStorage;
