export const microfrontendRoutes = {
  '@react-ib-mf/pagos2web-ui': '/pagos',
  // "@react-ib-mf/header-menu-ui",
  // "@react-ib-mf/home-interna-ui",
  "@react-ib-mf/onboarding-ui": '/onboarding',
  "@react-ib-mf/consultivoweb-ui": '/consultas/extractos',
  "@react-ib-mf/movimientos-ui": '/consultas/movimientos',
  "@react-ib-mf/saldos-acuerdos-ui": '/consultas/saldos',
  "@react-ib-mf/visualizar-saldos-ui": '/visualizar-saldos',
  "@react-ib-mf/transferenciasweb-ui": '/transferencias',
  "@react-ib-mf/interpagos-ui": '/cobranzas',
  "@react-ib-mf/interlegajo-ui": '/interlegajo',
  "@react-ib-mf/inversiones-ui": '/inversiones',
  "@react-ib-mf/representante-legal-ui": '/modulo-representante-legal',
  "@react-ib-mf/bancos-digitales-ui": '/digitales',
  "@react-ib-mf/perfil-ui": '/perfil',
  "@react-ib-mf/react-ib-mf-tenants-ui": '/tenants',
  // "@react-ib-mf/notifications-ui",
  "@react-ib-mf/collaborators-ui": "/colaboradores",
  "@react-ib-mf/esquemasdefirmas-ui": 'esquemasdefirmas',
  "@react-ib-mf/rules-inv-ui": 'reglas-automaticas'
};