import { getAppNames, getAppStatus } from 'single-spa';
import { microfrontendRoutes } from '../constants/mfRoutes';

const NAMES_APP_MF = getAppNames() || [];

export const getMicrofrontendRoutes = () => {
  return NAMES_APP_MF.map(appName => microfrontendRoutes[appName] || null).filter(Boolean)
};

export const getStatusMicrofrontendApp = routeCurrent => {
  const nameApp = NAMES_APP_MF.find(appName => routeCurrent === microfrontendRoutes[appName]
  )

  return getAppStatus(nameApp)
};