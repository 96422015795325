import { pushAnalyticsEvent } from '@wow/utils';
import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import { INotificationsRead } from '../../../interfaces/INotificationsPayloads';
import { INotificationList } from '../../context/IAppContextProvider';
import AppContext from '../../context/Provider';
import useWindowSize from '../../hooks/useWindowSize';
import { lowerCaseSnakeFormat } from '../../utils/converters';
import { notificationsReadFailed } from '../../utils/localStorageManager';
import { getMicrofrontendRoutes } from '../../utils/microfrotendHelpers';
import { getNumRepeatSekeleton } from '../../utils/screen-manager';
import CardContainer from '../CardContainer';
import useNotificationRead from '../CardContainer/hook/useNotificationRead';
import Drawer from '../Drawer';
import EmptyStateScreen from '../EmptyStateScreen';
import SkeletonOnLoading from '../SkeletonOnLoading';
import { skeletonStyle } from './styled';

function DrawerContainer() {
  const { isOpenDrawer, onCloseDrawer, isFetching, notificationList, getNotificationError, setModalConfig, markNotificationRead } =
    useContext(AppContext);
  const { width } = useWindowSize();
  const { setNotificationsToMarkAsRead } = useNotificationRead({ markNotificationRead });
  const navigate = useNavigate();
  const REPEAT_NUM_SKELETON = getNumRepeatSekeleton(width);

  const isInternalMicrofrontendUrl = (url: string): boolean => {
    const internalRoutes = getMicrofrontendRoutes();
    return internalRoutes.some(route => url.startsWith(route));
  };

  const handleInternalRedirect = ({ callToAction, isNotificationRead }: Partial<INotificationList>, notificationsToMarkAsRead) => {
    navigate(callToAction.url);
    if (!isNotificationRead) {
      setNotificationsToMarkAsRead(notificationsToMarkAsRead);
    }
    onCloseDrawer();
  };

  const handleExternalRedirect = async ({ callToAction, isNotificationRead }: Partial<INotificationList>, notificationsToMarkAsRead) => {
    const externalUrl = `${process.env.URL_BASE_SIB}${callToAction.url}`;
    const newWindow = window.open(externalUrl, '_blank');

    if (newWindow && !isNotificationRead) {
      await markNotificationRead(notificationsToMarkAsRead);
    }

    onCloseDrawer();
  };

  const handleShowPopUp = async ({ domainEvent, isNotificationRead }, notificationsToMarkAsRead) => {
    setModalConfig(prevState => ({
      ...prevState,
      isOpen: true,
      type: lowerCaseSnakeFormat(domainEvent),
    }));

    if (!isNotificationRead) {
      await markNotificationRead(notificationsToMarkAsRead);
    }

    onCloseDrawer();
  };

  const handleClickNotification = async ({ callToAction, id, isNotificationRead, domainEvent }: Partial<INotificationList>) => {
    const notificationSavedInLocalStorage: INotificationsRead[] = JSON.parse(localStorage.getItem(notificationsReadFailed)) || [];
    const notificationExists = notificationSavedInLocalStorage.find(notification => notification.notificationId === id);

    if (!notificationExists) {
      notificationSavedInLocalStorage.push({
        readAt: new Date().toISOString(),
        notificationId: id,
      });
    }

    pushAnalyticsEvent({
      event: 'select_content',
      conten_type: 'Menu header',
      item_id: domainEvent,
    });

    switch (callToAction?.actionType) {
      case 'REDIRECT':
        if (isInternalMicrofrontendUrl(callToAction.url)) {
          handleInternalRedirect({ callToAction, isNotificationRead }, notificationSavedInLocalStorage);
        } else {
          handleExternalRedirect({ callToAction, isNotificationRead }, notificationSavedInLocalStorage);
        }
        break;

      case 'POPUP':
        handleShowPopUp({ domainEvent, isNotificationRead }, notificationSavedInLocalStorage);
        break;

      default:
        onCloseDrawer();
        break;
    }
  };

  if (!isOpenDrawer) {
    return null;
  }

  const DrawerWrapper = (
    <Drawer isOpen={isOpenDrawer} onClose={onCloseDrawer}>
      {isFetching && <SkeletonOnLoading id='DrawerContainer' repeatNumSkeleton={REPEAT_NUM_SKELETON} sx={skeletonStyle} />}
      {!notificationList?.length && !getNotificationError && !isFetching && <EmptyStateScreen errorVariant='no_results' />}
      {getNotificationError && !isFetching && <EmptyStateScreen errorVariant='error_connection' />}
      {!isFetching && notificationList?.length > 0 && (
        <CardContainer notificationList={notificationList} handleClickNotification={handleClickNotification} />
      )}
    </Drawer>
  );

  return createPortal(DrawerWrapper, document.body);
}

export default DrawerContainer;
