import { useState, useLayoutEffect } from 'react';

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({ width: null, height: null });
  const TIME_TIMEOUT = 250

  useLayoutEffect(() => {
    let resizeTimer;
    function handleResize() {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, TIME_TIMEOUT);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}
