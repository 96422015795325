import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { CSSProperties } from 'react';
import notFoundImage from '../../assets/enlace-roto.png';
import { RoutesName } from '../../routes/routesNames';

interface IErrorPage {
  titleText: string;
  bodyText: string;
  linkText: string;
  callbackAction: () => void;
  redirectTo: string;
  titleStyle: CSSProperties;
  bodyStyle: CSSProperties;
  variant: string;
}

function ErrorPage({
  titleText,
  bodyText,
  linkText,
  callbackAction,
  titleStyle,
  bodyStyle,
  redirectTo = RoutesName.ROOT,
  variant = 'default',
}: Partial<IErrorPage>) {
  const intl = useIntl();
  return (
    <StyledErrorPage>
      <div className='error-page'>
        <div className='error-page__image' />

        <h4 style={titleStyle} className='error-page__title'>
          {intl.formatMessage({ id: `components.errorPage.${variant}.title`, defaultMessage: titleText })}
        </h4>

        <p style={bodyStyle} className='error-page__body'>
          {intl.formatMessage({ id: `components.errorPage.${variant}.bodyText`, defaultMessage: bodyText })}
        </p>

        {/* If a callback is passed as param it is executed onclick */}
        {callbackAction && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a className='error-page__action' aria-hidden='true' onClick={() => callbackAction()}>
            {intl.formatMessage({ id: `components.errorPage.${variant}.linkText`, defaultMessage: linkText })}
          </a>
        )}
        {/* Otherwise if a redirect is passed as param it is redirected onclick */}
        {redirectTo && (
          <Link as={RouterLink} className='error-page__action' to={redirectTo}>
            {intl.formatMessage({ id: `components.errorPage.${variant}.linkText`, defaultMessage: linkText })}
          </Link>
        )}
      </div>
    </StyledErrorPage>
  );
}

export default ErrorPage;

const StyledErrorPage = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  text-align: center;
  flex-direction: column;

  .error-page {
    padding-top: 66px;
    &__image {
      background-image: url(${notFoundImage});
      background-position: center;
      background-size: cover;
      width: 80px;
      height: 80px;
      margin: auto;
    }
    &__title {
      font-family: Red Hat Display;
      margin-top: 16px;
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
    &__body {
      margin: 8px 0 24px 0;
      font-weight: 400;
      font-size: 16px;
      font-family: 'Inter';
    }
    &__action {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      text-decoration-line: underline;
      color: #2172dc;
      cursor: pointer;
    }
  }
`;
