import './styled/fonts';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
// eslint-disable-next-line import/no-unresolved
import { Fonts, PrivateRender, theme as ThemeData } from '@react-ib-mf/style-guide-ui';
import { ButtonChakra as Button } from '@wow/button';
import { DrawerChakra as Drawer } from '@wow/drawer';
import { SpinnerChakra as Spinner, SkeletonChakra as Skeleton } from '@wow/progress-indicator';
import { BreadcrumbChakra as Breadcrumb } from '@wow/breadcrumb';
import { AvatarChakra as Avatar } from '@wow/avatar';
import { AccordionChakra as Accordion } from '@wow/accordion';
import { SwitchChakra as Switch } from '@wow/switch';
import { ModalChakra as Modal } from '@wow/modal';
// eslint-disable-next-line import/no-unresolved
import { themeChakra } from '@wow/themes';
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import translations from '../i18n/locales';
import Routes from './routes/Routes';

const { ToastContainer } = createStandaloneToast();

export const theme = extendTheme({
  ...ThemeData,
  textStyles: {
    ...themeChakra.textStyles,
  },
  colors: {
    ...themeChakra.colors,
  },
  components: {
    Button,
    Drawer,
    Spinner,
    Skeleton,
    Breadcrumb,
    Avatar,
    Accordion,
    Switch,
    Modal,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export function RootContainer({ children }: { children: React.ReactNode }) {
  const [locale] = useState('es-AR');
  dayjs.locale(locale.split('-')[0]);

  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <Fonts />
          <IntlProvider locale={locale} messages={translations[locale]}>
            <BrowserRouter>{children}</BrowserRouter>
          </IntlProvider>
          <ToastContainer />
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}

export function Root() {
  return (
    <RootContainer>
      <Routes />
    </RootContainer>
  );
}
