import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';

import { getOperatorNotificationsConfig, updateOperatorNotificationsConfig } from '../../services/api';
import CommonPage from '../components/CommonPage';
import EmptyStateScreen from '../components/EmptyStateScreen';
import Loader from '../components/Loader';
import { textSettings, textLoader } from '../constants/generalText';
import ConfigNotification from '../components/ConfigNotification/ConfigNotification';

function Settings() {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  const {
    data: operatorNotificationsConfig,
    isLoading: isLoadingOperatorNotificationsConfig,
    error: operatorNotificationsConfigError,
  } = useQuery({
    queryKey: ['getOperatorNotificationsConfig'],
    queryFn: getOperatorNotificationsConfig,
    select: data => data?.data?.data,
  });

  const { mutateAsync: updateOperatorNotificationsConfigMutation, isLoading: isLoadingUpdateOperatorNotificationsConfig } = useMutation(
    async payload => {
      const timeoutId = setTimeout(() => setShowLoadingScreen(true), 3000);
      await updateOperatorNotificationsConfig(payload).finally(() => {
        setShowLoadingScreen(false);
        clearTimeout(timeoutId);
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getOperatorNotificationsConfig'] }),
    },
  );

  return (
    <>
      <CommonPage
        subheader={intl.formatMessage({ id: 'layouts.settings.subheader', defaultMessage: textSettings.subheader })}
        text={intl.formatMessage({ id: 'layouts.settings.text', defaultMessage: textSettings.text })}
      />
      <Box className='setting-container_box'>
        {isLoadingOperatorNotificationsConfig && <Loader />}
        {operatorNotificationsConfigError && !isLoadingOperatorNotificationsConfig && <EmptyStateScreen errorVariant='error_connection' />}
        {!isLoadingOperatorNotificationsConfig && !operatorNotificationsConfigError && (
          <ConfigNotification
            operatorNotificationsConfig={operatorNotificationsConfig}
            isLoadingUpdateOperatorNotificationsConfig={isLoadingUpdateOperatorNotificationsConfig}
            updateOperatorNotificationsConfigMutation={updateOperatorNotificationsConfigMutation}
          />
        )}

        {showLoadingScreen && isLoadingUpdateOperatorNotificationsConfig && (
          <LoadingScreen
            title={intl.formatMessage({ id: 'components.loader.title', defaultMessage: textLoader.title })}
            description={intl.formatMessage({ id: 'components.loader.description', defaultMessage: textLoader.description })}
          />
        )}
      </Box>
    </>
  );
}

export default Settings;
