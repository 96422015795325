// eslint-disable-next-line import/prefer-default-export
export const textNotifications = 'Notificaciones';
export const textSettings = {
  subheader: 'Configurá tus notificaciones',
  text: 'Las notificaciones que habilites las verás en la web. Pronto podrás configurar distintas notificaciones para cada dispositivo.',
};
export const textLoader = {
  title: 'Estamos procesando tu información',
  description: 'Esto puede demorar unos instantes',
};
