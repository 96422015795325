import { useIntl } from 'react-intl';

import { DomainEventEnum } from '../../../constants/constants';
import { lowerCaseSnakeFormat } from '../../../utils/converters';

const configModal = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();
  const BANK_ENROLLMENT_APPROVED = lowerCaseSnakeFormat(DomainEventEnum['ADMINISTRATION.ACCOUNTS.BANK_ENROLLMENT_APPROVED']);

  return {
    [BANK_ENROLLMENT_APPROVED]: {
      iconType: 'info' as const,
      title: intl.formatMessage({
        id: `components.Modal.${BANK_ENROLLMENT_APPROVED}.title`,
      }),
      description: intl.formatMessage({
        id: `components.Modal.${BANK_ENROLLMENT_APPROVED}.description`,
      }),
      successButton: intl.formatMessage({
        id: `components.Modal.${BANK_ENROLLMENT_APPROVED}.successButton`,
      }),
    },
  };
};
export default configModal;
