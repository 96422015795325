import { IconButtonComponent } from '@wow/button';
import { Drawer as DrawerDS, DrawerBody, DrawerHeader } from '@wow/drawer';
import { Settings } from 'react-feather';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { textNotifications } from '../../constants/generalText';
import { RoutesName } from '../../routes/routesNames';

interface IDrawer {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

function Drawer({ isOpen, onClose, children }: IDrawer) {
  const navigate = useNavigate();
  const intl = useIntl();

  const handleClickNavigation = () => {
    navigate(RoutesName.CONFIGURACIONES);
    onClose();
  };

  return (
    <DrawerDS isOpen={isOpen} onClose={onClose} size='lg' data-testid='drawer-inner'>
      <DrawerHeader>
        <DrawerHeader.Title>{intl.formatMessage({ id: 'components.drawer.title', defaultMessage: textNotifications })}</DrawerHeader.Title>
        {/* <IconButtonComponent
          variant='icon-text'
          sx={{ position: 'absolute', right: '1rem', top: '-3rem' }}
          icon={<Settings />}
          size='i-md'
          color='secondary.100'
          data-testid='icon-setting'
          aria-label='icon-button'
          onClick={handleClickNavigation}
        /> */}
      </DrawerHeader>

      <DrawerBody>{children}</DrawerBody>
    </DrawerDS>
  );
}
export default Drawer;
