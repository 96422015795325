import React from 'react';
import { Box, Text, Switch, Flex } from '@chakra-ui/react';
import { PropsBodyAccordion } from '../INotificationsConfig';

const BodyAccordion = (props: PropsBodyAccordion) => {

  const {
    notifications,
    resultConfig,
    handleNotificationSwitchChange,
    isLoadingUpdateOperatorNotificationsConfig,
  } = props;

  return (
    <>
      {notifications?.map((notification, index) => {
        const { domainEvent, channels } = notification;
        const config = resultConfig?.[domainEvent];

        return (
          <React.Fragment key={`${domainEvent}-${index}`}>
            <Flex justifyContent='space-between' alignItems='center' p='12px 14px' minH='64px' borderBottom='1px solid #C7C6CA'>
              <Box>
                <Text fontSize='14px' fontWeight='600' lineHeight='20px' color='black' data-testid='accordion-category-subTitle'>
                  {config.subTitle}
                </Text>
                <Text fontSize='12px' lineHeight='21px' data-testid='accordion-category-description'>
                  {config.description}
                </Text>
              </Box>

              <>
                {channels?.map((channel, index) => {
                  const { channelType, isEnabled } = channel;

                  return (
                    <React.Fragment key={`${domainEvent}-${channelType}-${index}`}>
                      <Switch
                        name={channelType}
                        isChecked={isEnabled}
                        onChange={() =>
                          handleNotificationSwitchChange({
                            domainEvent,
                            channelType,
                            isEnabled: !isEnabled,
                          })
                        }
                        isDisabled={isLoadingUpdateOperatorNotificationsConfig}
                        data-testid='accordion-category-switch'
                        marginRight='8px'
                      />
                    </React.Fragment>
                  );
                })}
              </>
            </Flex>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default BodyAccordion;
