import CardNotification from './components/CardNotification';
import { INotificationList } from '../../context/IAppContextProvider';

interface Props {
  notificationList: Partial<INotificationList>[];
  handleClickNotification: (params: Partial<INotificationList>) => void;
}

const CardContainer = ({ handleClickNotification, notificationList }: Props) => {
  return (
    <>
      {notificationList.map((item, index) => (
        <CardNotification
          key={`CardContainer-${item.id || index}`}
          {...item}
          handleClickNotification={handleClickNotification}
        />
      ))}
    </>
  );
};

export default CardContainer;
