import styled from 'styled-components';

const Style = styled.div`
  height: 100%;
  position: relative;
  width: 95%;
  margin-bottom: 150px;
  margin-left: 28px;
`;
export default Style;
