import styled from 'styled-components';
import { CardSection } from '@wow/card';
import { themeChakra } from '@wow/themes';

const {
  colors: { primary, neutral },
} = themeChakra;
const TextContainer = styled.div``;

const CardSectionHorizontal = styled(CardSection)`
  .metaContainer{
    width: 12px;
  }
  gap: 8px;
  min-height: 109px;
  ${TextContainer} {
    > p:first-of-type {
      color: ${neutral[110]};
    }
    > p:last-of-type {
      width: 412px;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    flex: 1 0 0;
  }
  &:hover {
    background-color: ${primary[50]};
  }
`;

const styleCardContainer = {
  borderRadius: 'initial',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
}
export { CardSectionHorizontal, TextContainer, styleCardContainer };
