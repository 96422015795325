export const getNotificationQueryKey = 'get-notifications';

export const DomainEventType = [
  'ADMINISTRATION.ACCOUNTS.BANK_ENROLLMENT_APPROVED',
  'ADMINISTRATION.ACCOUNTS.BANK_ENROLLMENT_REJECTED',
  'ADMINISTRATION.ACCOUNTS.BANK_ENROLLMENT_PENDING',
];

export type DomainEventType = (typeof DomainEventType)[number];
export const DomainEventEnum = Object.fromEntries(DomainEventType.map(value => [value, value])) as Record<DomainEventType, DomainEventType>;
