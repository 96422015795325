import { Avatar, Icon } from '@chakra-ui/react';
import { IconButtonComponent } from '@wow/button';
import { pushAnalyticsEvent } from '@wow/utils';
import { useContext } from 'react';
import { FiBell } from 'react-icons/fi';

import AppContext from '../../context/Provider';
import { StyledNotificationsButton } from './styled';

function Bell() {
  const { onOpenDrawer, countNotifications } = useContext(AppContext);

  const handleShowDrawer = async () => {
    await pushAnalyticsEvent({
      event: 'select_content',
      conten_type: 'Menu header',
      item_id: 'CTA Notificacion',
    });
    onOpenDrawer();
  };

  return (
    <StyledNotificationsButton data-testid='component-bell'>
      <IconButtonComponent
        aria-label='icon-button'
        variant='icon-text'
        size='i-md'
        icon={<Icon as={FiBell} w={24} h={24} />}
        onClick={handleShowDrawer}
      />
      {countNotifications > 0 && (
        <Avatar size='xs' name=' ' fontFamily='Inter' fontSize='10px' lineHeight={1} data-testid='bell-meta'>
          {countNotifications}
        </Avatar>
      )}
    </StyledNotificationsButton>
  );
}

export default Bell;
