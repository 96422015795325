import { useDisclosure } from '@chakra-ui/react';
import { getToken } from '@react-ib-mf/style-guide-ui';
import { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AppContext from './Provider';
import { IContextProviderProps, INotificationList } from './IAppContextProvider';
import { getNotificationQueryKey } from '../constants/constants';
import { handleMarkNotificationReadError, handleMarkNotificationReceivedError } from '../utils/errorManager';
import { clearFailedPayloadFromLocalStorage, notificationsReadFailed, notificationsReceivedFailed } from '../utils/localStorageManager';
import { getNotifications, markNotificationAsReadRequest, markNotificationAsReceivedRequest } from '../../services/api';
import { INotificationsRead, INotificationsReceived } from '../../interfaces/INotificationsPayloads';

function AppContextProvider({ children }: IContextProviderProps) {
  const [countNotifications, setCountNotifications] = useState(0);
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose } = useDisclosure();
  const [modalConfig, setModalConfig] = useState(null);
  const queryClient = useQueryClient();

  const onCloseDrawer = () => {
    onClose();
    queryClient.setQueryData([getNotificationQueryKey], { data: [] });

    queryClient.removeQueries({
      queryKey: [getNotificationQueryKey],
    });
  };

  useEffect(() => {
    const wSocket = new WebSocket(`${process.env.WS_API_BASE_URL}?token=${getToken()}&source=WEB`);
    wSocket.onopen = () => {
      console.log('Conexión WebSocket abierta');
    };

    wSocket.onmessage = event => {
      console.log('Message from WebSocket:', event.data);
      const data = JSON.parse(event.data);
      console.log('Message from WebSocket:', data);

      setCountNotifications(data.unreceivedNotifications);
    };

    wSocket.onerror = error => console.error('Error en WebSocket:', error);
    return () => {
      wSocket.onclose = () => console.log('Conexión WebSocket cerrada');
    };
  }, []);

  const {
    data: notificationList,
    isFetching,
    error: getNotificationError,
    refetch: refetchGetnotifications,
  } = useQuery<INotificationList[], Error>({
    queryKey: [getNotificationQueryKey],
    queryFn: getNotifications,
    enabled: isOpenDrawer,
    initialData: [],
    onSuccess: (data: INotificationList[]) => {
      if (data.length > 0) {
        const notificationsToMarkAsReceived = data.filter(notification => !notification.isNotificationReceived);
        if (notificationsToMarkAsReceived.length > 0) {
          const notificationSavedInLocalStorage = JSON.parse(localStorage.getItem(notificationsReceivedFailed)) || [];

          const newNotificationsToMarkAsReceived: INotificationsReceived[] = [
            ...notificationSavedInLocalStorage,
            {
              receivedAt: new Date().toISOString(),
              notificationIds: notificationsToMarkAsReceived?.map(notificacion => notificacion.id),
            },
          ];

          markNotificationReceived(newNotificationsToMarkAsReceived);
        }
        setCountNotifications(0);
      }
    },
    select: data => data,
  });

  const { mutateAsync: markNotificationRead } = useMutation({
    mutationFn: async (payload: INotificationsRead[]) => {
      try {
        await markNotificationAsReadRequest({ notifications: payload });
        clearFailedPayloadFromLocalStorage(notificationsReadFailed);
      } catch (markNotificationReadError) {
        handleMarkNotificationReadError(payload);
      }
    },
  });

  const { mutateAsync: markNotificationReceived } = useMutation({
    mutationFn: async (payload: INotificationsReceived[]) => {
      try {
        await markNotificationAsReceivedRequest({ notifications: payload });
        clearFailedPayloadFromLocalStorage(notificationsReceivedFailed);
      } catch (markNotificationReceivedError) {
        handleMarkNotificationReceivedError(payload);
      }
    },
  });

  useEffect(() => {
    if (isOpenDrawer && countNotifications > 0) {
      refetchGetnotifications();
    }
  }, [countNotifications, isOpenDrawer]);

  const value = useMemo(
    () => ({
      notificationList,
      isOpenDrawer,
      onOpenDrawer,
      onCloseDrawer,
      isFetching,
      getNotificationError,
      countNotifications,
      modalConfig,
      setModalConfig,
      markNotificationRead,
      markNotificationReceived,
    }),
    [
      notificationList,
      isOpenDrawer,
      onOpenDrawer,
      onCloseDrawer,
      isFetching,
      getNotificationError,
      countNotifications,
      modalConfig,
      setModalConfig,
      markNotificationRead,
      markNotificationReceived,
    ],
  );
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppContextProvider;
