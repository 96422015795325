import React from 'react';

function UnderConstruction() {
  return (
    <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg' data-testid='under-construction'>
      <g id='under-construction'>
        <g id='Vector'>
          <path
            d='M40 3C37.0826 3 34.2847 4.15893 32.2218 6.22183C30.1589 8.28473 29 11.0826 29 14C29 16.9174 30.1589 19.7153 32.2218 21.7782C34.2847 23.8411 37.0826 25 40 25C42.9174 25 45.7153 23.8411 47.7782 21.7782C49.8411 19.7153 51 16.9174 51 14C51 11.0826 49.8411 8.28473 47.7782 6.22183C45.7153 4.15893 42.9174 3 40 3Z'
            fill='#AAC7FF'
          />
          <path
            d='M40 4C45.514 4 50 8.486 50 14C50 19.514 45.514 24 40 24C34.486 24 30 19.514 30 14C30 8.486 34.486 4 40 4ZM40 2C38.4241 2 36.8637 2.31039 35.4078 2.91345C33.9519 3.5165 32.629 4.40042 31.5147 5.51472C30.4004 6.62902 29.5165 7.95189 28.9134 9.4078C28.3104 10.8637 28 12.4241 28 14C28 15.5759 28.3104 17.1363 28.9134 18.5922C29.5165 20.0481 30.4004 21.371 31.5147 22.4853C32.629 23.5996 33.9519 24.4835 35.4078 25.0866C36.8637 25.6896 38.4241 26 40 26C43.1826 26 46.2348 24.7357 48.4853 22.4853C50.7357 20.2348 52 17.1826 52 14C52 10.8174 50.7357 7.76515 48.4853 5.51472C46.2348 3.26428 43.1826 2 40 2Z'
            fill='#2172DC'
          />
          <path d='M34 12H46V16H34V12Z' fill='white' />
          <path d='M9 41.642H17V77H9V41.642Z' fill='#AAC7FF' />
          <path d='M16 42.642V76H10V42.642H16ZM18 40.642H8V78H18V40.642Z' fill='#2172DC' />
          <path d='M17 61H63V67H17V61Z' fill='#AAC7FF' />
          <path d='M62 62V66H18V62H62ZM64 60H16V68H64V60Z' fill='#2172DC' />
          <path d='M63 41.642H71V77H63V41.642Z' fill='#AAC7FF' />
          <path d='M70 42.642V76H64V42.642H70ZM72 40.642H62V78H72V40.642Z' fill='#2172DC' />
          <path d='M3 25H77V43H3V25Z' fill='#AAC7FF' />
          <path d='M76 26V42H4V26H76ZM78 24H2V44H78V24Z' fill='#2172DC' />
          <path
            d='M20.616 26H15.076L4 42H9.538L20.616 26ZM34.462 26H28.924L17.846 42H23.384L34.462 26ZM48.308 26H42.77L31.692 42H37.23L48.308 26ZM62.154 26H56.616L45.538 42H51.076L62.154 26ZM76 26H70.462L59.384 42H64.924L76 26ZM73.23 42H76V38L73.23 42ZM6.77 26H4V30L6.77 26Z'
            fill='white'
          />
        </g>
      </g>
    </svg>
  );
}

export default UnderConstruction;
