/* eslint-disable import/prefer-default-export */
import Binoculars from './Binoculars';
import UnderConstruction from './UnderConstruction';

export const CONFIG = {
  no_results: {
    title: 'Nada por aquí',
    subtitle: 'Todavía no tenés notificaciones.',
    image: <Binoculars />,
  },
  error_connection: {
    title: 'La información no está disponible',
    subtitle: 'Estamos trabajando para solucionarlo, intentá de nuevo en unos minutos.',
    image: <UnderConstruction />,
  },
};
