import { useEffect, useState } from 'react';
import { getStatusMicrofrontendApp } from '../../../utils/microfrotendHelpers';

interface IDataNotification {
  readAt: Date;
  id: string | number;
}

const useNotificationRead = ({ markNotificationRead }) => {
  const [notificationsToMarkAsRead, setNotificationsToMarkAsRead] = useState<IDataNotification>(null);

  const checkAppBeforeAPIRequest = async () => {
    const routeCurrent = window.location.pathname;

    if (getStatusMicrofrontendApp(routeCurrent) === 'MOUNTED') {
      await markNotificationRead(notificationsToMarkAsRead);
    }

  };

  useEffect(() => {
    const handleRoutingEvent = () => {
      checkAppBeforeAPIRequest()
    };

    if (notificationsToMarkAsRead) {
      window.addEventListener('single-spa:routing-event', handleRoutingEvent);
    }
    return () => {
      if (notificationsToMarkAsRead) {
        window.removeEventListener('single-spa:routing-event', handleRoutingEvent);
      }
    };
  }, [notificationsToMarkAsRead]);

  return { setNotificationsToMarkAsRead };
};

export default useNotificationRead;
